import axios from 'axios';

const api = axios.create({
  baseURL: window.location.host.includes('localhost')
    ? 'http://' + window.location.host + '/admin'
    : 'https://' + window.location.host + '/admin',
  headers: {
    'Content-Type': 'application/json',
  },
});

window.api = api;

const getDemos = async (params) => {
  let options = {};
  options.idToken = params.idToken

  if (params.username) {
    options.username = params.username
  }

  const urlParams = new URLSearchParams(options)
  const demosUrl = `/api/demos?${urlParams.toString()}`

  const json = await api.get(demosUrl);
  return json.data;
};

const createDemo = async (params) => {
  const json = await api.post('/api/demos', {
    name: params.name,
    type: params.type,
    username: params.username,
    publishableKey: params.publishableKey,
    secretKey: params.secretKey,
    idToken: params.idToken
  });

  return json.data;
};

const updateDemo = async (params) => {
  const json = await api.post(`/api/demos/${params.id}`, {
    name: params.name,
    type: params.type,
    publishableKey: params.publishableKey,
    secretKey: params.secretKey,
    idToken: params.idToken
  });

  return json.data;
};

const deleteDemo = async (id, idToken) => {
  const json = await api.post(`/api/demos/${id}/delete`, {
    idToken: idToken
  });

  return json.data;
};

const getDemo = async (id, idToken) => {
  const urlParams = new URLSearchParams({ idToken: idToken })
  const json = await api.get(`/api/demos/${id}?${urlParams.toString()}`);

  return json.data;
};

export {
  getDemos,
  createDemo,
  getDemo,
  updateDemo,
  deleteDemo
};

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Icon from '@stripe-internal/sail/dist/Icon';
import NavItem from '@stripe-internal/sail/dist/NavItem';
import Box from '@stripe-internal/sail/dist/Box';
import { AuthContext } from './AuthContext';

class Sidebar extends Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: window.location.href.split("/").slice(-1).pop()
    };
  }

  getIcon(icon) {
    return {
      default: <Icon icon={icon} color="gray" size={16} />,
      selected: <Icon icon={icon} color="blue" size={16} />,
    };
  }

  renderLink(link) {
    const navItem = (
      <NavItem
        activeState={this.state.selectedItem === link.id ? 'selected' : 'default'}
        contents={link.label}
        icon={this.getIcon(link.icon)}
        onClick={() => this.setState({selectedItem: link.id})}
      />
    );

    return <Link to={link.href}>{navItem}</Link>
  }

  renderLinks() {
    const SIDEBAR_LINKS = [
      {
        id: 'configure',
        label: 'Configure new demo',
        icon: 'addCircle',
        href: '/admin/customize'
      },
      {
        id: 'demos',
        label: 'Recent demos',
        icon: 'business',
        href: '/admin/demos'
      },
      {
        id: 'yourdemos',
        label: 'Your demos',
        icon: 'person',
        href: `/admin/demos/${this.context.state.user.username}`
      }
    ];

    return SIDEBAR_LINKS.map((link) => this.renderLink(link));
  }

  render() {
    return (
      <Box padding={20}>
        <div style={{width: '300px'}}>
          {this.renderLinks()}
       </div>
     </Box>
    );
  };
};

export default Sidebar;

import React, { Component } from 'react';
import { AuthContext } from './AuthContext';
import { Link } from "react-router-dom";
import Box from '@stripe-internal/sail/dist/Box';
import Card from '@stripe-internal/sail/dist/Card';
import Button from '@stripe-internal/sail/dist/Button';
import {ContentHeader} from '@stripe-internal/sail/dist/Content';
import {ContentBlock} from '@stripe-internal/sail/dist/Content';
import DataTable, {
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableRow,
} from '@stripe-internal/sail/dist/DataTable';

import moment from 'moment';
import * as backend from '../backend';

class DemosList extends Component {

  static contextType = AuthContext

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    backend.getDemos({
      username: this.props.username, 
      idToken: this.context.state.user.idToken
    }).then((demos) => this.setState({data: demos}))
  }

  render() {
    const rows = this.state.data.map((demo, i) => {
      return (
        <DataTableRow key={i}>
          <DataTableCell emphasized>{demo.name}</DataTableCell>
          <DataTableCell>{demo.type}</DataTableCell>
          <DataTableCell>{demo.username}</DataTableCell>
          <DataTableCell>{moment(demo.createdAt).format("MMMM Do YYYY")}</DataTableCell>
          <DataTableCell>{demo._id}</DataTableCell>
          <DataTableCell><Link to={`/admin/customize/${demo._id}`}><Button icon="settings" hideLabel label="Settings" /></Link></DataTableCell>
          <DataTableCell><Button color="blue" label="View" href={`/${demo.type}/${demo._id}`} /></DataTableCell>
        </DataTableRow>
      );
    });

    return (
      <Box padding={20}>
        <Card>
          <Box padding={{top: 20, left: 20, right: 20}}>
            <ContentHeader divider={false} title="Most Recent 100 Demos" >
            </ContentHeader>
          </Box>
          <Box padding={20}>
            <DataTable>
              <DataTableHead
                columns={[
                  {label: 'Demo Name'},
                  {label: 'Type'},
                  {label: 'Created By'},
                  {label: 'Created At'},
                  {label: 'Id'},
                  {label: 'Edit'},
                  {label: 'Open'},
                ]}
              />
              <DataTableBody>{rows}</DataTableBody>
            </DataTable>
          </Box>
        </Card>
      </Box>
    );
  };
};

export default DemosList;

import React, { Component } from 'react';
import Box from '@stripe-internal/sail/dist/Box';
import Card from '@stripe-internal/sail/dist/Card';
import Button from '@stripe-internal/sail/dist/Button';
import {Body} from '@stripe-internal/sail/dist/Text';
import DataTable, {
  DataTableBody,
  DataTableCell,
  DataTableHead,
  DataTableRow,
} from '@stripe-internal/sail/dist/DataTable';
import moment from 'moment';

class Changelog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  render() {
    return (
      <Box padding={20}>
        <Card>
          <Box padding={20}>
            <DataTable>
              <DataTableHead
                columns={[
                  {label: 'Date'},
                  {label: 'Description'},
                  {label: 'Commit'},
                ]}
              />
              <DataTableBody>
                <DataTableRow key={1}>
                  <DataTableCell emphasized>Changelog items coming soon!</DataTableCell>
                  <DataTableCell></DataTableCell>
                  <DataTableCell></DataTableCell>
                </DataTableRow>
              </DataTableBody>
            </DataTable>
          </Box>
        </Card>
      </Box>
    );
  };
};

export default Changelog;

import React, { Component } from 'react';
import Box from '@stripe-internal/sail/dist/Box';
import Card from '@stripe-internal/sail/dist/Card';
import FormLayout, {FormBlock, FormField, FormRow} from '@stripe-internal/sail/dist/FormLayout';
import {TextInput} from '@stripe-internal/sail/dist/Input';
import {Body, Subheading} from '@stripe-internal/sail/dist/Text';
import {ToggleBox, ToggleBoxItem} from '@stripe-internal/sail/dist/ToggleBox';
import {ContentHeader} from '@stripe-internal/sail/dist/Content';
import Button from '@stripe-internal/sail/dist/Button';
import {ContentBlock} from '@stripe-internal/sail/dist/Content';
import {Section} from '@stripe-internal/sail/dist/Section';
import {PositiveNotice, NegativeNotice, AttentionNotice} from '@stripe-internal/sail/dist/Notice';
import {InlineLink} from '@stripe-internal/sail/dist/InlineLink';
import { AuthContext } from './AuthContext';
import * as backend from '../backend';

class Customize extends Component {

  static contextType = AuthContext

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: 'payments',
      publishableKey: '',
      secretKey: '',
      error: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    const response = await backend.createDemo({
      name: this.state.name,
      type: this.state.type,
      username: this.context.state.user.username,
      publishableKey: this.state.publishableKey,
      secretKey: this.state.secretKey,
      idToken: this.context.state.user.idToken
    });

    if (response.error) {
      this.setState({error: response.error});
    } else {
      this.props.history.push('/admin/demos');
    }
  }

  render() {
    return (
      <Box padding={20}>
        { this.state.error
          ? <Section>
              <ContentBlock>
                <NegativeNotice
                  title="Error"
                  description={this.state.error}
                />
              </ContentBlock>
            </Section>
          : null
        }
        <Card>
          <Box padding={20}>
            <form>
              <FormLayout background="white">
                <ContentHeader title="Configure a new demo" >
                  <br />
                  <ContentBlock divider={false} style={{maxWidth: "1000px"}}>
                    <Body>
                      <Subheading>Instructions</Subheading>
                      <br />
                      To get started, you'll need a Stripe account to hook up to this demo. If you don't have one,
                      <a href="https://confluence.corp.stripe.com/display/SOLUTIONS/Stripe+Account+setup"> checkout our guide </a>
                      on getting started with a Stripe account and how to create them for demos. <strong><u>You should create a new Stripe account for every demo you create here. Reusing a Stripe account is not recommended.</u></strong>
                      <br /><br />
                      Once you have a Stripe account to use,
                      you'll need to copy and paste the account's <a href="https://dashboard.stripe.com/test/apikeys">API keys </a> into the
                      appropriate fields below. Note we only allow you to use test keys. Feel free to drop into the #demos slack channel for
                      any help getting a Stripe account or using this form to setup a new demo.
                      <br /><br />
                      <Subheading>Demo Branding</Subheading>
                      <br />
                      Each demo comes with a default branding which includes a logo and simple color scheme.
                      However you can change the demo to show a different logo or set of colors by updating
                      the <a href="https://dashboard.stripe.com/settings/branding"> Brand settings </a>
                      of the Stripe account you're using for this demo.
                      <br /><br />
                      You can use the <a href="https://tool-color-extractor.stripedemos.com/" target="_blank">color extractor tool</a> to identify the predominant color and the color palette of a website screenshot or logo to match the branding of the demo you are building.
                    </Body>
                  </ContentBlock>
                </ContentHeader>
                <FormBlock>
                  <FormRow name="Usage-demoType" label="Demo Type">
                    <ToggleBox direction="horizontal" name="tbox">
                      <ToggleBoxItem
                        checked={this.state.type === 'payments'}
                        onChange={() => this.setState({type: 'payments'})}
                        label='One-time Payments'
                        description="(Elements)"
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'terminal'}
                        onChange={() => this.setState({type: 'terminal'})}
                        label="Terminal"
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'fixed-billing'}
                        onChange={() => this.setState({type: 'fixed-billing'})}
                        label='Billing'
                      />
                    </ToggleBox>
                    <ToggleBox direction="horizontal" name="tbox">
                      <ToggleBoxItem
                        checked={this.state.type === 'checkout'}
                        onChange={() => this.setState({type: 'checkout'})}
                        label='One-time Payments'
                        description="(Checkout)"
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'checkout-subscriptions'}
                        onChange={() => this.setState({type: 'checkout-subscriptions'})}
                        label='Subscriptions'
                        description="(Checkout)"
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'express-connect'}
                        onChange={() => this.setState({type: 'express-connect'})}
                        label="Marketplace"
                        description="(Express Connect)"
                      />
                    </ToggleBox>
                  </FormRow>
                  { this.state.type == 'express-connect' ? (
                  <FormRow label=" ">
                    <AttentionNotice
                      title="Special Stripe account setup required"
                      description={
                        <span>
                          Please be sure you have completed the
                          <InlineLink href="https://confluence.corp.stripe.com/display/SOLUTIONS/Create+a+branded+demo+for+Connect"> Stripe account setup steps </InlineLink>
                          prior to pasting in API keys and launching your Marketplace (Express Connect) demo. If you need help, please don't hesitate to reach out in the #demos channel!
                        </span>
                      }
                    />
                  </FormRow>
                  ) : (
                    <div />
                  )}
                  <FormRow name="name" label="Demo Name">
                    <FormField>
                      <TextInput
                        id="name"
                        width="maximized"
                        placeholder="Something memorable"
                        onChange={(name) => this.setState({name})}
                        value={this.state.name}
                      />
                    </FormField>
                  </FormRow>
                  <FormRow name="publishableKey" label="Publishable Key">
                    <FormField>
                      <TextInput
                        id="publishableKey"
                        width="maximized"
                        placeholder="A Stripe account publishable key"
                        onChange={(publishableKey) => this.setState({publishableKey})}
                        value={this.state.publishableKey}
                      />
                    </FormField>
                  </FormRow>
                  <FormRow name="secretKey" label="Secret Key">
                    <FormField>
                      <TextInput
                        id="secretKey"
                        width="maximized"
                        placeholder="A Stripe account secret key"
                        onChange={(secretKey) => this.setState({secretKey})}
                        value={this.state.secretKey}
                      />
                    </FormField>
                  </FormRow>
                </FormBlock>
                <Box padding={12} flex={{justifyContent: 'flex-end'}}>
                  <Button
                    icon="addCircle"
                    color="blue"
                    onClick={this.handleSubmit}
                    label="Create"
                  />
                </Box>
              </FormLayout>
            </form>
          </Box>
        </Card>
      </Box>
    );
  };
};

export default Customize;

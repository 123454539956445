import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AuthContext } from './AuthContext';
import Layout from './Layout';
import DemosList from './DemosList';
import Changelog from './Changelog';
import Customize from './Customize';
import CustomizeExisting from './CustomizeExisting';
import Box from '@stripe-internal/sail/dist/Box';
import Button from '@stripe-internal/sail/dist/Button';
import { GoogleLogin } from 'react-google-login';
import { GOOGLE_CLIENT_ID } from './Constants';

const AuthProvider = ({ children }) => {
  const [state, setState] = React.useState({
    status: null,
    error: null,
    user: null,
  })

  const login = (response) => {
    if (response.accessToken) {
      setState(state => ({
        status: 'success',
        error: null,
        user: {
          authenticated: true,
          accessToken: response.accessToken,
          idToken: response.tokenId,
          username: response.profileObj.email.split("@")[0]
        }
      }));
    }
  }

  const logout = (response) => {
    setState(state => (
      {
        status: null,
        error: null,
        user: null
      }
    ));
  }

  const handleLoginFailure = (response) => {
    logout(response);
  }

  const handleLogoutFailure = (response) => {
    logout(response);
  }

  const callbacks = {
    login: login,
    logout: logout,
    handleLoginFailure: handleLoginFailure,
    handleLogoutFailure: handleLogoutFailure
  }

  return (
    <AuthContext.Provider value={{ state: state, callbacks: callbacks }}>
      {state.status === 'pending' ? (
        'Loading...'
      ) : state.status === 'error' ? (
        <div>
          <div>
            <pre>{state.error.message}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  )
}

const Login = () => {
  const {callbacks} = React.useContext(AuthContext)

  return (
    <Box style={{marginTop: '250px', textAlign: 'center'}}>
      <GoogleLogin
        clientId={ GOOGLE_CLIENT_ID }
        buttonText='Login'
        onSuccess={ callbacks.login }
        onFailure={ callbacks.handleLoginFailure }
        cookiePolicy={ 'single_host_origin' }
        responseType='code,token'
        isSignedIn={true}
        render={renderProps => (
          <Button onClick={renderProps.onClick} disabled={renderProps.disabled} color="blue" label="Sign in"/>
        )}
      />
    </Box>
  );
}

const AuthenticatedApp = () => {
  const { state } = React.useContext(AuthContext);
  const username = state.user.username;

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/" render={() => {return <Redirect to="/admin/customize" />}} exact/>
          <Route path="/admin" render={() => {return <Redirect to="/admin/demos" />}} exact/>
          <Route path="/admin/demos" render={() => <DemosList key="alldemos" />} exact />
          <Route path="/admin/demos/:username" render={() => <DemosList key="yourdemos" username={username} />} exact />
          <Route path="/admin/customize" component={Customize} exact />
          <Route path="/admin/customize/:id" component={CustomizeExisting} auth={state.user} />
          <Route path="/admin/changelog" component={Changelog} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

const UnauthenticatedApp = () => {
  return <Login />
}

const Content = () => {
  const { state } = React.useContext(AuthContext);
  return state.user ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <AuthProvider>
        <Content />
      </AuthProvider>
    );
  }
};

export default App;

import React from 'react';
import '@stripe-internal/sail/dist/sail.css';
import BrandedHeader from '@stripe-internal/sail/dist/BrandedHeader';
import Box from '@stripe-internal/sail/dist/Box';
import Button from '@stripe-internal/sail/dist/Button';
import Sidebar from './Sidebar';
import { GOOGLE_CLIENT_ID } from './Constants';
import { GoogleLogout } from 'react-google-login';
import { AuthContext } from './AuthContext';

const Layout = (props) => {

  const { callbacks } = React.useContext(AuthContext)

  return (
    <div id='layout-container'>
      <Box flex={{direction: 'row'}} style={{width: '100%'}}>
        <Box style={{width: 260}}>
          <Box style={{width: '40vw'}}>
            <BrandedHeader
              gradient={{
                base: 'a78ce9',
                baseEnd: 'fa755a',
                inner: '45b2e8',
                innerEnd: '3ecf8e',
                outer: '8898aa',
              }}
              title="Branded Demos"
            />
          </Box>
          <Sidebar />
          <Box padding={20}>
            <GoogleLogout
              clientId={ GOOGLE_CLIENT_ID }
              buttonText='Logout'
              onLogoutSuccess={ callbacks.logout }
              onFailure={ callbacks.handleLogoutFailure }
              render={renderProps => (
                <Button onClick={renderProps.onClick} disabled={renderProps.disabled} color="blue" label="Sign out"/>
              )}
            />
          </Box>
        </Box>
        <Box flex={{direction: 'column'}} margin={{top: 20}} padding={20} style={{flex: '1 1 100%', overflow: 'auto', zIndex: 1}}>
          {props.children}
        </Box>
      </Box>
    </div>
  );
};

export default Layout

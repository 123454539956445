import React, { Component } from 'react';
import Box from '@stripe-internal/sail/dist/Box';
import Card from '@stripe-internal/sail/dist/Card';
import FormLayout, {FormBlock, FormField, FormRow} from '@stripe-internal/sail/dist/FormLayout';
import {TextInput} from '@stripe-internal/sail/dist/Input';
import {Body} from '@stripe-internal/sail/dist/Text';
import {ToggleBox, ToggleBoxItem} from '@stripe-internal/sail/dist/ToggleBox';
import Button, {ButtonGroup} from '@stripe-internal/sail/dist/Button';
import {Dialog, DialogContent, DialogFooter, DialogHeader} from '@stripe-internal/sail/dist/Dialog';
import ModalLayer from '@stripe-internal/sail/dist/ModalLayer';
import {ContentHeader} from '@stripe-internal/sail/dist/Content';
import { AuthContext } from './AuthContext';
import * as backend from '../backend';

class CustomizeExisting extends Component {

  static contextType = AuthContext

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      type: '',
      publishableKey: '',
      secretKey: '',
      accountId: '',
      modalOpen: false,
      user: props.auth
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { match: { params } } = this.props;

    await backend.updateDemo({
      id: params.id,
      name: this.state.name,
      idToken: this.context.state.user.idToken
    });

    this.props.history.push('/admin/demos');
  }

  handleModalClose = () => {
    this.setState({modalOpen: false});
  }

  handleModalOpen = () => {
    this.setState({modalOpen: true});
  }

  renderModal = () => {
    return (
      <ModalLayer
        shown={this.state.modalOpen}
        onWashClick={this.handleModalOpen}
        onEscape={this.handleModalClose}
      >
        <Dialog width="medium">
          <DialogHeader>Delete demo</DialogHeader>
          <DialogContent padding={{all: 20}}>
            <Body>Are you sure you want to permanently delete this demo?</Body>
          </DialogContent>
          <DialogFooter
            end={
              <ButtonGroup>
                <Button color="white" label="Cancel" onClick={this.handleModalClose} />
                <Button color="red" label="Delete demo" onClick={this.handleDeleteDemo} />
              </ButtonGroup>
            }
          />
        </Dialog>
      </ModalLayer>
    )
  }

  handleDeleteDemo = async (e) => {
    e.preventDefault();
    const { match: { params } } = this.props;
    await backend.deleteDemo(params.id, this.context.state.user.idToken);

    this.props.history.push('/admin/demos');
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    backend.getDemo(params.id, this.context.state.user.idToken)
      .then((demo) => {
        this.setState({
          name: demo.name,
          type: demo.type,
          publishableKey: demo.publishableKey,
          secretKey: demo.secretKey,
          accountId: demo.accountId
        })
      })
  }

  render() {
    return (
      <Box padding={20}>
        <Card>
          <Box padding={20}>
            <form>
              <FormLayout background="white">
                <ContentHeader title="Modify a demo" />
                <FormBlock>
                  <FormRow name="Usage-demoType" label="Demo Type">
                    <ToggleBox direction="horizontal" name="tbox">
                      <ToggleBoxItem
                        checked={this.state.type === 'payments'}
                        onChange={() => this.setState({type: 'payments'})}
                        label='Payments'
                        disabled
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'terminal'}
                        onChange={() => this.setState({type: 'terminal'})}
                        label="Terminal"
                        disabled
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'fixed-billing'}
                        onChange={() => this.setState({type: 'fixed-billing'})}
                        label='Billing'
                        disabled
                      />
                      <ToggleBoxItem
                        checked={this.state.type === 'express-connect'}
                        onChange={() => this.setState({type: 'express-connect'})}
                        label='Connect'
                        disabled
                      />
                    </ToggleBox>
                  </FormRow>
                  <FormRow name="name" label="Demo Name">
                    <FormField>
                      <TextInput
                        id="name"
                        width="maximized"
                        placeholder="Something memorable"
                        onChange={(name) => this.setState({name})}
                        value={this.state.name}
                      />
                    </FormField>
                  </FormRow>
                  <FormRow name="accountId" label="Account ID">
                    <FormField>
                        <TextInput
                          id="accountId"
                          width="maximized"
                          placeholder="account id"
                          value={this.state.accountId}
                          disabled
                        />
                      </FormField>
                  </FormRow>
                  <FormRow name="publishableKey" label="Publishable Key">
                    <FormField>
                      <TextInput
                        id="publishableKey"
                        width="maximized"
                        placeholder="A Stripe account publishable key"
                        onChange={(publishableKey) => this.setState({publishableKey})}
                        value={this.state.publishableKey}
                        disabled
                      />
                    </FormField>
                  </FormRow>
                  <FormRow name="secretKey" label="Secret Key">
                    <FormField>
                      <TextInput
                        id="secretKey"
                        width="maximized"
                        placeholder="A Stripe account secret key"
                        onChange={(secretKey) => this.setState({secretKey})}
                        value={"sk_test_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" + this.state.secretKey.slice(-4)}
                        disabled
                      />
                    </FormField>
                  </FormRow>
                </FormBlock>
                <Box padding={12} flex={{justifyContent: 'flex-end'}}>
                  <ButtonGroup>
                    <Button
                      icon="trash"
                      color="red"
                      onClick={this.handleModalOpen}
                      label="Delete"
                    />
                    <Button
                      icon="changeCircle"
                      color="blue"
                      onClick={this.handleSubmit}
                      label="Update"
                    />
                    {this.renderModal()}
                  </ButtonGroup>
                </Box>
              </FormLayout>
            </form>
          </Box>
        </Card>
      </Box>
    );
  };
};

export default CustomizeExisting;
